import router from "next/router"
import { removeCookie } from '@/utils/cookies'
import { AxiosErrorUnwrapType } from "./bffRequest"

export const handleTokenExpired = async <T>(apiCall: () => Promise<T>): Promise<T> => {
  try {
    const result = await apiCall()
    return result
  } catch (error) {
    if ((error as AxiosErrorUnwrapType)?.code === "invalid_access_token") {
      removeCookie("environment")
      await router.push("/api/auth/logout")
    }
    throw error
  }
}