import React, { useEffect } from 'react'
import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useUser } from '@auth0/nextjs-auth0'
import FullPageLoading from '@/components/organism/layout/fullPageLoading'
import type { App } from '@/pages/api/[env]/apps'
import { getApps } from '@/api/app'
import { handleTokenExpired } from '@/utils/handleTokenExpired'
import { useEnvironment } from '@/hooks/useEnvironment'
import { getEnvironmentText } from '@/utils/environment'

const { publicRuntimeConfig } = getConfig()

const LandingPage = (props: LandingPageProps) => {
  const router = useRouter()
  const { user, error, isLoading } = useUser()
  const { invitation, organization, organizationName } = props
  const { isSandbox } = useEnvironment()
  const environmentText = getEnvironmentText(isSandbox, publicRuntimeConfig.APP_ENV)

  useEffect(() => {
    setTimeout(async () => {
      if (invitation) {
        router.push({
          pathname: `${publicRuntimeConfig.AUTH0_ISSUER_BASE_URL}/authorize`,
          query: {
            invitation,
            organization,
            organization_name: organizationName,
            client_id: publicRuntimeConfig.AUTH0_CLIENT_ID,
            response_type: 'token',
            redirect_uri: `${publicRuntimeConfig.AUTH0_BASE_URL}/landing`,
          },
        })
      } else if (!isLoading && !user) {
        router.push({
          pathname: '/api/auth/login',
        })
      } else if (!isLoading && user) {
        const asyncGetApps = () => getApps(environmentText)
        const responseData: App[] = await handleTokenExpired<App[]>(asyncGetApps)
        if (responseData.length === 0) {
          router.push({
            pathname: '/404'
          })
        } else {
          router.push({
            pathname: `/env/production/apps/${responseData[0].id}/overview`,
          })
        }
      }
    }, 2000);
  })

  if (error) return <div>{error.message}</div>
  return (<FullPageLoading waitingText={`Hold on, You're being redirected.`} />)
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context
  if (query.invitation) {
    return {
      props: {
        invitation: query.invitation,
        organization: query.organization,
        organizationName: query.organization_name
      },
    }
  }

  return {
    props: {},
  }
}

type LandingPageProps = {
  invitation: string
  organization: string
  organizationName: string
  appEnv: string
}

export default LandingPage
