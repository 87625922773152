import Image from 'next/image'

type FullPageLoadingProps = {
  waitingText: string
}

const FullPageLoading = (props: FullPageLoadingProps) => (
  <>
    <div className="top-0 left-0 absolute">
      <Image src="/landing/logo-bg-left.svg" alt="logo-loading" width={660} height={520} />
    </div>

    <div className="bottom-0 right-0 absolute">
      <Image src="/landing/logo-bg-right.svg" alt="logo-loading" width={535} height={390} />
    </div>

    <div className="flex justify-center w-full h-full items-center absolute m-0 left-0">
      <div className="flex flex-col items-center -ml-10">
        <Image src="/landing/loading.svg" alt="logo-loading" width={60} height={60} />
        <p className="text-sm	text-neutral-500 font-normal mt-7 animate-logo-fade-in">{props.waitingText}</p>
      </div>
    </div>
  </>
)

export default FullPageLoading
