import { useAtom } from "jotai"
import {
  isEnvironmentSelectedAtom
} from '@/store/environment'
import { getEnvironmentText } from '@/utils/environment'
import getConfig from 'next/config'
import { useRouter } from 'next/router'

const { publicRuntimeConfig } = getConfig()
const appEnv = publicRuntimeConfig.APP_ENV
export const useEnvironment = () => {
  const [isEnvironmentSelected, setEnvironmentWasSelected] = useAtom(isEnvironmentSelectedAtom)
  const router = useRouter()
  const isSandbox = router.query?.environment === 'sandbox'

  const environmentText = getEnvironmentText(isSandbox, appEnv)

  return {
    appEnv,
    environmentText,
    isSandbox,
    isEnvironmentSelected,
    environmentUrl: isSandbox ? 'sandbox' : 'production',
    setEnvironmentWasSelected: () => setEnvironmentWasSelected(true)
  }
}
